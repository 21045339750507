import React, { useState, useRef } from 'react'
import {
  Box,
  Typography,
  IconButton,
  createStyles,
  makeStyles,
  Theme,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Button,
  Card,
} from '@material-ui/core'
import { ReactComponent as OptionsIcon } from '../icons/kebab.svg'
import { ReactComponent as TrashIcon } from '../icons/trash_can.svg'
import { ReactComponent as CalendarIcon } from '../icons/calendar.svg'
import { ReactComponent as EditIcon } from '../icons/edit_pencil.svg'
import { secondary, primary, white } from '../loudcrowd-theme'
import IntegrationLogo from '../components/IntegrationLogo'
import { EspMessageRewardFragmentFragment } from './operations/esp-message-reward-fragment.generated'
import { formatUTCDateToClientLocalTime } from '../utils/date-format'

interface StyledProps {
  isScheduled: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuPaper: {
      minWidth: 170,
    },
    integrationLogo: {
      height: 25,
      flex: 0.5,
    },
    listItem: {
      color: theme.palette.text.secondary,
    },
    listItemIcon: {
      minWidth: 28,
      color: 'inherit',
      justifyContent: 'center',
      marginRight: theme.spacing(2),
    },
    cardContainer: {
      width: 327,
      height: 327,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundImage: (props: StyledProps) =>
        props.isScheduled ? 'linear-gradient(180deg, #009E8E 0%, #38D3FF 50%)' : 'none',
      boxShadow: '0px 10px 20px rgba(37, 36, 103, 0.1)',
      border: (props: StyledProps) => (props.isScheduled ? 'none' : `1px solid ${secondary[400]}`),
      borderRadius: 20,
    },
    messageCard: {
      width: '97%',
      height: '97%',
      borderRadius: 14,
      backgroundColor: white,
      padding: 20,
    },
    messageCardContainer: {
      display: 'flex',
      width: '100%',
      height: '100%',
      flexDirection: 'column',
    },
    messageCardHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      flex: 0,
    },
    dateScheduleButton: {
      fontSize: 12,
      borderRadius: 15,
      padding: '5px 12px',
      borderColor: (props: StyledProps) => (props.isScheduled ? '#009E8E' : primary[500]),
      color: (props: StyledProps) => (props.isScheduled ? '#009E8E' : primary[500]),
      borderWidth: 2,
    },
    dateText: {
      fontSize: 12,
      padding: '10px 0 0',
    },
    iconButton: {
      padding: 4,
    },
    messageCardBody: {
      minHeight: 100,
      paddingTop: 20,
      paddingBottom: 10,
      flex: 1,
      display: 'flex',
      alignItems: 'center',
    },
    ellipsisOverflow: {
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    messageText: {
      color: secondary[600],
      marginTop: 4,
    },
    messageCardFooter: {
      display: 'flex',
      alignItems: 'flex-end',
      flex: 0,
    },
    options: {
      width: 27,
      height: 8,
    },
    integrationProperties: {
      fontSize: 12,
      padding: '2px 0',
    },
  }),
)

interface EspMessageComponentProps {
  espMessageReward: EspMessageRewardFragmentFragment
  onEdit: (espMessageReward: EspMessageRewardFragmentFragment) => void
  onSchedule: (espMessageReward: EspMessageRewardFragmentFragment) => void
  onDelete: (id: string) => void
}

function EspMessageRewardComponent({
  espMessageReward,
  onEdit,
  onSchedule,
  onDelete,
}: EspMessageComponentProps): React.ReactElement {
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false)
  const menuRef = useRef<HTMLButtonElement>(null)
  const isMessageScheduled = !!espMessageReward.scheduledJob

  const classes = useStyles({ isScheduled: !!espMessageReward.scheduledJob })

  const { cardBodyText, messageDateText } = getEspMessageComponentText(espMessageReward)

  return (
    <Card className={`${classes.cardContainer}`}>
      <Box className={classes.messageCard}>
        <Box className={classes.messageCardContainer}>
          <Box className={classes.messageCardHeader}>
            <Box>
              <Button
                variant="outlined"
                className={classes.dateScheduleButton}
                onClick={() => {
                  onSchedule(espMessageReward)
                }}
              >
                <CalendarIcon style={{ width: 18, height: 18, marginRight: 5 }} />
                {!!espMessageReward.scheduledJob ? 'Scheduled' : 'Schedule'}
              </Button>
              <Typography className={classes.dateText}>{messageDateText}</Typography>
            </Box>
            <IconButton ref={menuRef} onClick={() => setMenuOpen(true)} className={classes.iconButton}>
              <OptionsIcon className={classes.options} />
            </IconButton>
            <Menu
              open={isMenuOpen}
              anchorEl={menuRef.current}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              getContentAnchorEl={null}
              onClose={() => setMenuOpen(false)}
              classes={{ paper: classes.menuPaper }}
            >
              <MenuItem
                className={classes.listItem}
                onClick={() => {
                  onEdit(espMessageReward)
                  setMenuOpen(false)
                }}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <EditIcon />
                </ListItemIcon>
                <ListItemText disableTypography>Edit</ListItemText>
              </MenuItem>
              <MenuItem
                className={classes.listItem}
                onClick={() => {
                  onSchedule(espMessageReward)
                  setMenuOpen(false)
                }}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <CalendarIcon />
                </ListItemIcon>
                <ListItemText disableTypography>{isMessageScheduled ? 'Change Schedule' : 'Schedule'}</ListItemText>
              </MenuItem>
              {(espMessageReward.rewardBatchJobs?.results || []).length === 0 && (
                <MenuItem
                  className={classes.listItem}
                  onClick={() => onDelete(espMessageReward.id.toString())}
                  disabled={(espMessageReward.rewardBatchJobs?.results || []).length > 0}
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    <TrashIcon />
                  </ListItemIcon>
                  <ListItemText disableTypography>Delete</ListItemText>
                </MenuItem>
              )}
            </Menu>
          </Box>
          <Box className={classes.messageCardBody}>
            <Box>
              <Box>
                <Typography variant="subtitle1" style={{ WebkitLineClamp: 2 }}>
                  {espMessageReward.name}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="body2"
                  className={`${classes.ellipsisOverflow} ${classes.messageText}`}
                  style={{ WebkitLineClamp: cardBodyText.length >= 40 ? 1 : 2 }}
                >
                  {cardBodyText}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className={classes.messageCardFooter}>
            <Box
              style={{ backgroundColor: '#F7F3FD' }}
              display="flex"
              flex={1}
              flexDirection={'row'}
              alignItems={'center'}
              padding={5}
              marginTop={2}
              borderRadius={10}
              width="100%"
            >
              <IntegrationLogo
                integrationType={espMessageReward.integration?.integrationType!}
                className={classes.integrationLogo}
              />
              <Box flex="1" marginLeft={10}>
                <Typography className={classes.integrationProperties}>{espMessageReward.integration?.name}</Typography>
                {!!espMessageReward.eventId && (
                  <Typography className={classes.integrationProperties}>{espMessageReward.eventId}</Typography>
                )}
                {!!espMessageReward.emailTemplateName && (
                  <Typography className={classes.integrationProperties}>
                    {espMessageReward.emailTemplateName}
                  </Typography>
                )}
                {!!espMessageReward.bluecoreCampaignId && (
                  <Typography className={classes.integrationProperties}>
                    {espMessageReward.bluecoreCampaignId}
                  </Typography>
                )}
                {!!espMessageReward.templateId && (
                  <Typography className={classes.integrationProperties}>{espMessageReward.templateId}</Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Card>
  )
}

function getEspMessageComponentText(espMessageReward: EspMessageRewardFragmentFragment): {
  cardBodyText: string
  messageDateText: string
} {
  const programNames = espMessageReward.programs?.map(p => p.name) || []
  let cardBodyText = `No recipients`
  if (programNames.length > 0) {
    cardBodyText = `${programNames.join(', ')}`
  }

  let date = espMessageReward.scheduledJob?.scheduledAt
  let messageDateText = 'Not scheduled'
  const rewardBatchJobs = espMessageReward?.rewardBatchJobs?.results || []
  let lastRunBatchJob = rewardBatchJobs.length > 0 ? rewardBatchJobs[rewardBatchJobs.length - 1] : null
  const lastRunBatchJobDate = lastRunBatchJob?.startedAt

  if (date) {
    const formattedDate = formatUTCDateToClientLocalTime(new Date(date), { month: 'short' })
    messageDateText = `Sends on ${formattedDate}`
  } else if (!!lastRunBatchJobDate) {
    const formattedDate = formatUTCDateToClientLocalTime(new Date(lastRunBatchJobDate), { month: 'short' })
    messageDateText = `Last sent on ${formattedDate}`
  }

  return { cardBodyText, messageDateText }
}

export default EspMessageRewardComponent
