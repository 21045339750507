import { Box, Button, TextField, Theme, createStyles, makeStyles } from '@material-ui/core'
import { ShopifyForm, CustomStoreForm, CommerceCloudStoreForm } from './EcommIntegrationModal'
import React, { useEffect, useState } from 'react'
import { SelectField } from '../components/SelectField/SelectField'
import { primary, secondary } from '../loudcrowd-theme'
import { InfluencerListBlockInput, SegmentType } from '../gql-global'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    webComponentList: {},
    listHeader: {
      color: primary[600],
      fontSize: 18,
      fontWeight: 'bold',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    webComponent: {
      marginBottom: theme.spacing(2),
      border: `1px solid ${secondary[600]}`,
      padding: theme.spacing(2),
      fontSize: 14,
    },
    webComponentTitle: {
      fontSize: 14,
      fontWeight: 'bold',
      color: primary[600],
    },
    webComponentTitleRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    webComponentTitleRemoveButton: {
      color: primary[600],
      cursor: 'pointer',
    },
    addNew: {
      fontSize: 14,
      fontWeight: 'bold',
      color: primary[600],
    },
    listEmptyState: {
      border: `1px solid ${secondary[600]}`,
      padding: theme.spacing(2),
      fontSize: 14,
    },
    listEmptyStateCTA: {
      fontSize: 14,
      fontWeight: 'bold',
      color: primary[600],
      width: '100%',
      padding: 0,
    },
  }),
)

export interface EcommIntegrationWebComponentListProps {
  setValue: (value: InfluencerListBlockInput[]) => void
  ecommForm: ShopifyForm | CustomStoreForm | CommerceCloudStoreForm
  defaultSegment: { id: string; label: string }
  segments: SegmentType[]
  errorMessages: { index: number; field: 'blockId' | 'filterSegmentId'; message: string }[]
}

export const EcommIntegrationWebComponentList: React.FC<EcommIntegrationWebComponentListProps> = ({
  ecommForm,
  setValue,
  defaultSegment,
  segments,
  errorMessages,
}) => {
  const styles = useStyles()
  const [newBlocks, setNewBlocks] = useState(ecommForm.influencerListBlocks)

  useEffect(() => {
    // if ecommForm.influencerListBlocks has items with undefined filterSegmentId, set them to defaultSegment.id
    if (!ecommForm.influencerListBlocks) return
    const blocks = ecommForm.influencerListBlocks.map(block => ({
      id: block.id,
      blockId: block.blockId,
      filterSegmentId: block.filterSegmentId || defaultSegment.id,
    }))
    setNewBlocks(blocks)
  }, [ecommForm.influencerListBlocks, defaultSegment.id])

  function setSegmentValue(index: number, value: string) {
    if (!newBlocks) return
    setValue(
      newBlocks?.map((block, i) => ({
        id: block.id,
        blockId: block.blockId || undefined,
        filterSegmentId: i === index ? value : block.filterSegmentId,
      })),
    )
  }
  function handleBlockIdChange(index: number, value: string) {
    if (!newBlocks) return
    setValue(
      newBlocks?.map((block, i) => ({
        id: block.id,
        blockId: i === index ? value : block.blockId || undefined,
        filterSegmentId: block.filterSegmentId,
      })),
    )
  }

  function handleAddComponent() {
    let blocks = newBlocks || []
    blocks.push({ id: undefined, blockId: undefined, filterSegmentId: defaultSegment.id })

    setValue(blocks)
  }

  function handleRemoveComponent(index: number) {
    return () => {
      if (!newBlocks) return
      setValue(
        newBlocks
          ?.filter((_, i) => i !== index)
          .map((block, i) => ({
            id: block.id,
            blockId: block.blockId || undefined,
            filterSegmentId: block.filterSegmentId,
          })),
      )
    }
  }

  return (
    <Box marginTop={2} className={styles.webComponentList}>
      <Box className={styles.listHeader}>Web Component Settings</Box>
      {newBlocks?.map((block, index) => (
        <Box key={index} display="flex" flexDirection="column" className={styles.webComponent}>
          <Box className={styles.webComponentTitleRow}>
            <Box className={styles.webComponentTitle}>Influencer List Block</Box>
            {index > 0 && (
              <Button className={styles.webComponentTitleRemoveButton} onClick={handleRemoveComponent(index)}>
                Remove
              </Button>
            )}
          </Box>
          <TextField
            label="Component Identifier"
            value={block.blockId || ''}
            placeholder={`create a unique ID, e.g. “homepage” to target this block on your website`}
            error={!!errorMessages.find(e => e.index === index && e.field === 'blockId')}
            helperText={errorMessages.find(e => e.index === index && e.field === 'blockId')?.message}
            onChange={e => handleBlockIdChange(index, e.target.value)}
          />
          <SelectField
            setValue={(value: string | number) => setSegmentValue(index, value.toString())}
            options={[defaultSegment, ...segments.map(i => ({ id: i.id, label: i.name }))]}
            value={block.filterSegmentId}
            label="Select which storefront creators to display on your page"
            name="segment"
            errors={errorMessages
              .filter(e => e.index === index && e.field === 'filterSegmentId')
              .map(e => e.message)
              .join()}
          />
        </Box>
      ))}
      {!!!newBlocks?.length && (
        <Box display="flex" flexDirection="row" alignItems="start" className={styles.listEmptyState}>
          <Box mr={4} mt={2}>
            <img
              alt="ILP promo"
              width="100%"
              src="https://loudcrowd.com/wp-content/uploads/2024/05/Influencer-List-Page.png"
            />
          </Box>
          <Box>
            <Box mt={2} mb={2} className={styles.webComponentTitle}>
              Influencer List Block
            </Box>
            <Box>
              Make it easy for visitors to browse and shop top-selling creator storefronts by adding Influencer List
              blocks on pages you care about the most.
            </Box>
          </Box>
          <Box></Box>
          <Button variant="text" className={styles.listEmptyStateCTA} onClick={handleAddComponent}>
            + Add
          </Button>
        </Box>
      )}
      {!!newBlocks?.length && (
        <Button variant="text" className={styles.addNew} onClick={handleAddComponent}>
          + Add New Component
        </Button>
      )}
    </Box>
  )
}
