import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { TextField as FormikTextField } from 'formik-material-ui'
import { ReactComponent as CrossIcon } from '../../icons/cross.svg'
import { Field, Form, FormikProvider, useFormik, yupToFormErrors } from 'formik'
import * as yup from 'yup'
import MultiSelect from '../../components/MultiSelect'
import { IntegrationType } from '../../gql-global'
import { EspMessageRewardFragmentFragment } from '../operations/esp-message-reward-fragment.generated'
import { DateTimePicker } from '@material-ui/pickers'
import { roundToGreaterHalfHour } from '../../utils/date-format'

const espIntegrationTypes: IntegrationType[] = [
  IntegrationType.Mandrill,
  IntegrationType.Sendinblue,
  IntegrationType.Klaviyo,
  IntegrationType.Bluecore,
  IntegrationType.Emarsys,
  IntegrationType.Ometria,
]

interface AddEditEspMessageRewardModalProps {
  espMessageReward: EspMessageRewardFragmentFragment | null
  open: boolean
  onCancel(): void
  onSubmit(formFields: EspMessageFormFields, espRewardId?: string): void
  loading: boolean
  programs?: { id: string; name: string }[]
  integrations?: { id: string; integrationType: IntegrationType; name: string }[]
}

interface EventPropertyType {
  name: string
  value: string
}

const fieldSchema = {
  name: yup.string().trim().required('Name is required.'),
  integrationId: yup.string().trim().required('Integration is required.'),
  eventId: yup.string().required('Event ID is required.'),
  emailTemplateName: yup.string().trim().required('Email Template Name is required.'),
  discountCodeFieldName: yup.string().trim().required('Discount Code Field Name is required.'),
  templateId: yup.string().trim().required('Template ID is required.'),
  bluecoreCampaignId: yup.string().trim().required('Bluecore Campaign ID is required.'),
  programIds: yup.array().of(yup.string().trim()),
  scheduledDate: yup.date(),
  eventProperties: yup.array().of(
    yup.object().shape({
      name: yup.string().trim().required('Property name is required.'),
      value: yup.string().trim().required('Property value is required.'),
    }),
  ),
}

export interface EspMessageFormFields {
  name: string
  integrationId: string
  eventId: string
  emailTemplateName: string
  templateId: string
  bluecoreCampaignId: string
  programIds: string[]
  scheduledDate: Date | null
  eventProperties: EventPropertyType[]
}

const useStyles = makeStyles({
  dialogPaper: {
    width: '100%',
    height: '100%',
    maxWidth: '600px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  form: { flex: 1, maxWidth: 550, width: '100%', display: 'flex', flexDirection: 'column' },
  formContent: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    '& p': { margin: 0, fontSize: 12 },
  },
  formField: { marginTop: 25, '&:first-of-type': { margin: 0 } },
  dateFormField: { width: 300 },
  dropdown: { maxWidth: 300, flex: 1 },
  formActions: { paddingBottom: 25 },
  tableContainer: {
    marginTop: 20,
    borderRadius: 10,
    outline: '1px solid #DAD9E6',
    '& th': { padding: '10px' },
    '& td': { padding: '10px' },
  },
  tableRow: {
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  },
})

export function getIntegrationFields(integrationType: IntegrationType): Set<keyof EspMessageFormFields> {
  switch (integrationType) {
    case IntegrationType.Mandrill:
      return new Set(['emailTemplateName'] as const)
    case IntegrationType.Sendinblue:
      return new Set(['templateId'] as const)
    case IntegrationType.Klaviyo:
      return new Set(['eventId'] as const)
    case IntegrationType.Emarsys:
      return new Set(['eventId'] as const)
    case IntegrationType.Ometria:
      return new Set(['eventId'] as const)
    case IntegrationType.Bluecore:
      return new Set(['bluecoreCampaignId'] as const)
  }
  return new Set()
}

const AddEditEspMessageRewardModal = ({
  espMessageReward,
  open,
  onCancel,
  onSubmit,
  programs,
  integrations,
}: AddEditEspMessageRewardModalProps): React.ReactElement => {
  const classes = useStyles()
  const isEditModal = !!espMessageReward
  const disabled = !!espMessageReward && (espMessageReward.rewardBatchJobs?.results || []).length > 0
  const [integrationFields, setIntegrationFields] = React.useState<Set<keyof EspMessageFormFields>>(new Set())
  const [eventProperties, setEventProperties] = useState<EventPropertyType[]>([])

  const handleValidate = async (formValues: EspMessageFormFields) => {
    const fields = ['name', 'integrationId', ...Array.from(integrationFields || [])] as Array<
      keyof EspMessageFormFields
    >
    const schema = yup.object(
      fields.reduce((a, v) => {
        if (fieldSchema[v]) {
          return { ...a, [v]: fieldSchema[v] }
        }
        return a
      }, {}),
    )
    try {
      await schema.validate(formValues)
      return {}
    } catch (e) {
      if (e instanceof yup.ValidationError) {
        return yupToFormErrors(e)
      } else {
        throw e
      }
    }
  }

  const formik = useFormik<EspMessageFormFields>({
    initialValues: {
      name: espMessageReward?.name || '',
      eventId: espMessageReward?.eventId || '',
      emailTemplateName: espMessageReward?.emailTemplateName || '',
      integrationId: espMessageReward?.integration?.id || '',
      templateId: espMessageReward?.templateId || '',
      bluecoreCampaignId: espMessageReward?.bluecoreCampaignId || '',
      programIds: espMessageReward?.programs?.map(p => p.id) || [],
      scheduledDate: espMessageReward?.scheduledJob?.scheduledAt
        ? new Date(espMessageReward?.scheduledJob?.scheduledAt)
        : null,
      eventProperties: espMessageReward?.eventProperties || [],
    },
    validate: handleValidate,
    onSubmit: values => {
      onSubmit(values, espMessageReward?.id.toString())
    },
    enableReinitialize: true,
  })
  const { resetForm } = formik

  useEffect(() => {
    const selectedIntegration = !!formik.values.integrationId
      ? integrations?.find(i => i.id === formik.values.integrationId)
      : null

    const integrationFields = (
      !!selectedIntegration ? getIntegrationFields(selectedIntegration?.integrationType) : new Set()
    ) as Set<keyof EspMessageFormFields>

    setIntegrationFields(integrationFields)
  }, [formik.values.integrationId, integrations])

  useEffect(() => {
    resetForm()
    setEventProperties(espMessageReward?.eventProperties || [])
  }, [open, espMessageReward?.eventProperties, resetForm])

  return (
    <Dialog open={open} onClose={onCancel} classes={{ paper: classes.dialogPaper }}>
      <Box m={2} position={'absolute'}>
        <div>
          <IconButton onClick={onCancel}>
            <CrossIcon width={16} height={16} />
          </IconButton>
        </div>
      </Box>
      <Box
        m={0}
        p={2}
        pt={10}
        display="flex"
        flexDirection="column"
        flex={1}
        alignItems={'center'}
        overflow={'scroll'}
        style={{ overflowX: 'hidden' }}
      >
        <FormikProvider value={formik}>
          <Form className={classes.form} noValidate>
            <DialogTitle>{isEditModal ? '' : 'Create'} Message via Email Service Provider</DialogTitle>
            <DialogContent className={classes.formContent}>
              <Field
                className={classes.formField}
                label="Message name"
                name="name"
                component={FormikTextField}
                placeholder="Enter a unique name to organize your message list"
              />
              <Field
                className={classes.formField}
                style={{ marginTop: 20, width: 300 }}
                label="Message integration"
                name="integrationId"
                component={FormikTextField}
                disabled={disabled}
                select
              >
                {integrations
                  ?.filter(i => espIntegrationTypes.includes(i.integrationType))
                  ?.map(i => (
                    <MenuItem key={i.id} value={i.id}>
                      {i.name}
                    </MenuItem>
                  ))}
              </Field>
              {integrationFields.has('eventId') && (
                <>
                  <Field
                    className={classes.formField}
                    label="Message event ID"
                    name="eventId"
                    component={FormikTextField}
                    disabled={disabled}
                    placeholder="Enter ID to trigger a flow in your email service provider"
                  />
                  <Typography style={{ maxWidth: 600 }}>
                    After saving this message, the ID you entered will appear in your email service provider and you can
                    select it as the trigger to your flows
                  </Typography>
                </>
              )}

              {integrationFields.has('emailTemplateName') && (
                <>
                  <Field
                    className={classes.formField}
                    label="Email Template Name"
                    name="emailTemplateName"
                    component={FormikTextField}
                    disabled={disabled}
                  />
                  <Typography style={{ maxWidth: 600 }}>
                    The email template name you entered will be used to trigger the message in your email service
                    provider
                  </Typography>
                </>
              )}

              {integrationFields.has('templateId') && (
                <>
                  <Field
                    className={classes.formField}
                    label="Template Id"
                    name="templateId"
                    disabled={disabled}
                    component={FormikTextField}
                  />
                  <Typography style={{ maxWidth: 600 }}>
                    The template ID you entered will be used to trigger the message in your email service provider
                  </Typography>
                </>
              )}

              {integrationFields.has('bluecoreCampaignId') && (
                <>
                  <Field
                    className={classes.formField}
                    label="Bluecore Campaign ID"
                    name="bluecoreCampaignId"
                    component={FormikTextField}
                    disabled={disabled}
                  />
                  <Typography style={{ maxWidth: 600 }}>
                    The campaign ID you entered will be used to trigger the message in Bluecore
                  </Typography>
                </>
              )}

              <Box mt={5}>
                <Typography variant={'subtitle1'}>Email properties</Typography>
                <Typography>
                  Use these properties in the template editor of your email service provider to include personalized
                  information for a specific recipient. Links to your ecomm site will automatically log a creator into
                  their Storefront and Creator Widget.
                </Typography>
                <TableContainer className={classes.tableContainer}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Property Name</TableCell>
                        <TableCell>Property Value</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow className={classes.tableRow}>
                        <TableCell>customEcommUrl</TableCell>
                        <TableCell>
                          <TextField
                            placeholder="Enter URL to any page on your ecomm site"
                            value={eventProperties.find(p => p.name === 'customEcommUrl')?.value || ''}
                            onChange={e => {
                              const eventProperties = [{ name: 'customEcommUrl', value: e.target.value }]
                              setEventProperties(eventProperties)
                              formik.setFieldValue('eventProperties', eventProperties)
                            }}
                            style={{ width: '100%' }}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              <Box mt={10}>
                <Typography variant={'subtitle1'}>Select message recipients</Typography>
                <Box display={'flex'} flexDirection={'row'} gridGap={20}>
                  <MultiSelect
                    className={classes.dropdown}
                    disabled={disabled}
                    renderValue={() => {
                      const programIds = formik.values.programIds
                      if (programIds.length === 0) return <>Select programs</>
                      if (programIds.length === 1) return <>{programs?.find(p => p.id === programIds[0])?.name}</>
                      return <>{formik.values.programIds.length} programs</>
                    }}
                    selectedOptions={formik.values.programIds}
                    menuLabel="Select programs"
                    onApply={(selected: string[]) => {
                      formik.setFieldValue('programIds', selected)
                    }}
                    options={(programs?.map(p => ({ name: p.name, id: p.id })) || []) as any}
                  />
                </Box>
              </Box>

              <Box mt={10}>
                <Typography variant={'subtitle1'}>Schedule your message to send</Typography>
                <Box display="flex" flexDirection={'row'} alignItems={'center'}>
                  <DateTimePicker
                    okLabel="Schedule message"
                    name="scheduledDate"
                    className={classes.dateFormField}
                    label="Scheduled date and time"
                    minDate={new Date()}
                    minDateMessage="Date must be after today"
                    onChange={value => {
                      const now = new Date()
                      if ((value as Date) <= now) {
                        value = new Date()
                      }
                      value = roundToGreaterHalfHour(value as Date)
                      formik.setFieldValue('scheduledDate', value)
                    }}
                    value={formik.values.scheduledDate}
                    hideTabs={true}
                    type="datetime"
                  />
                </Box>
                <Typography style={{ maxWidth: 300 }}>
                  Time shown in your browser time zone. Your selected time was rounded to the nearest half hour.
                </Typography>
                {!!formik.values.scheduledDate && (
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ marginTop: 10 }}
                    onClick={() => {
                      formik.setFieldValue('scheduledDate', null)
                    }}
                  >
                    Cancel Activity
                  </Button>
                )}
              </Box>
            </DialogContent>
            <DialogActions className={classes.formActions}>
              <Button onClick={onCancel} variant="outlined" color="primary">
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Save
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Box>
    </Dialog>
  )
}

export default AddEditEspMessageRewardModal
