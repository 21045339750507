import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import { IntegrationRowFragmentDoc } from './integration-row.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateShopifyIntegrationMutationVariables = Types.Exact<{
  shopName: Types.Scalars['String'];
  queryParams: Types.ShopifyQueryParamsInput;
}>;


export type CreateShopifyIntegrationMutation = { __typename: 'Mutations', createShopifyIntegration?: { __typename: 'CreateShopifyIntegration', ok: boolean, integration?: { __typename: 'ShopifyIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, integrationType: Types.IntegrationType, name: string, displayName?: string | null, keepUnattributedOrders?: boolean | null, creatorWidget: boolean, creatorWidgetPosition: Types.EcommWidgetPosition, slug?: string | null, lcRelativeLogoUrl?: string | null, storeLogoUrl?: string | null, ilpFilterSegment?: { __typename: 'SegmentType', id: number } | null, influencerListBlocks?: Array<{ __typename: 'InfluencerListBlock', id: string, blockId?: string | null, filterSegment?: { __typename: 'SegmentType', id: number } | null }> | null } | null } | null };


export const CreateShopifyIntegrationDocument = gql`
    mutation CreateShopifyIntegration($shopName: String!, $queryParams: ShopifyQueryParamsInput!) {
  createShopifyIntegration(shopName: $shopName, queryParams: $queryParams) {
    ok
    integration {
      ...IntegrationRow
    }
  }
}
    ${IntegrationRowFragmentDoc}`;
export type CreateShopifyIntegrationMutationFn = Apollo.MutationFunction<CreateShopifyIntegrationMutation, CreateShopifyIntegrationMutationVariables>;

/**
 * __useCreateShopifyIntegrationMutation__
 *
 * To run a mutation, you first call `useCreateShopifyIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShopifyIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShopifyIntegrationMutation, { data, loading, error }] = useCreateShopifyIntegrationMutation({
 *   variables: {
 *      shopName: // value for 'shopName'
 *      queryParams: // value for 'queryParams'
 *   },
 * });
 */
export function useCreateShopifyIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<CreateShopifyIntegrationMutation, CreateShopifyIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateShopifyIntegrationMutation, CreateShopifyIntegrationMutationVariables>(CreateShopifyIntegrationDocument, options);
      }
export type CreateShopifyIntegrationMutationHookResult = ReturnType<typeof useCreateShopifyIntegrationMutation>;
export type CreateShopifyIntegrationMutationResult = Apollo.MutationResult<CreateShopifyIntegrationMutation>;
export type CreateShopifyIntegrationMutationOptions = Apollo.BaseMutationOptions<CreateShopifyIntegrationMutation, CreateShopifyIntegrationMutationVariables>;